import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useThemeContext } from "../../context/ThemeProvider";
import { useUserContext } from "../../context/UserProvider";
import { LocationIcon, MailIcon, SprintIcon } from "../util/Icons";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { GarminToggle } from "../util/Components";
import { Link } from "react-router-dom";

const AccountPage = () => {
  const { user, userDetails, userRaces, linkedDevice } = useUserContext();
  const [showStravaConnect, setShowStravaConnect] = useState(false);
  const [garminConnectEnabled, setGarminConnectEnabled] = useState(true);
  const [racesMap, setRacesMap] = useState(undefined);
  const { colors } = useThemeContext();

  const isStaging = window.location.origin === "https://mcc-staging-4525c.web.app" || window.location.origin === "http://localhost:3000";

  async function getAllRaces() {
    if (userRaces) {
      let tempRacesMap = [];
      let sortedRaces = Array.from(userRaces).sort((a, b) => {
        let date1 = new Date(a[1].raceInfo.raceDateStart);
        let date2 = new Date(b[1].raceInfo.raceDateStart);

        return date2.getTime() - date1.getTime();
      });

      for (const value of sortedRaces) {
        let giftCodesMap = [];
        // console.log(key, value);
        const e = value[1];
        if (e.giftCodes) {
          for (let id in e.giftCodes) {
            // console.log(id, e.giftCodes[id])
            const giftCodeDocRef = await getDoc(doc(db, "giftcodes", id));

            giftCodesMap.push({ id: id, name: e.giftCodes[id], used: giftCodeDocRef.data().used });
          }
        }
        // console.log(e);
        tempRacesMap.push(
          <div
            key={`${e.raceInfo.name}-${e.raceInfo.legName}`}
            className="grid grid-cols-[60px,1fr] items-center gap-x-3 rounded-md px-3 max-w-md mb-3"
          >
            <Link
              className="col-span-2 grid grid-cols-[60px,1fr] items-center gap-x-3 w-full"
              to={`/dashboard/${e.legId.replace(/^price_/, "")}`}
            >
              <img className="max-w-[56px]" src={e.raceInfo?.imageUrl} alt={`${e.raceInfo?.name}-logo`} />
              <div>
                <p className="font-semibold text-base mb-0">{e.raceInfo?.name}</p>
                <p className="text-xs mb-1">{e.raceInfo?.legName}</p>
                <p className="text-xs mb-0">{e.raceInfo?.date}</p>
              </div>
            </Link>
            {giftCodesMap.length > 0 && (
              <div className="w-full grid col-start-2 mb-3">
                <p className="font-semibold mt-1 mb-2">Gift Registration Coupons Purchased</p>
                {giftCodesMap.map((code) => {
                  return (
                    <div key={code.id} className="flex gap-3 items-center">
                      <div>{code.used ? "✓" : "o"}</div>
                      <p key={code.id} className="m-0 mt-1">
                        {code.id} - {code.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      }
      // })
      // console.log(tempRacesMap);
      setRacesMap((prev) => tempRacesMap);
    }
  }
  useEffect(() => {
    getAllRaces();
  }, [userRaces]);

  function requestStravaAuth() {
    const appId = isStaging ? "107147" : "104555";
    window.open(
      `http://www.strava.com/oauth/authorize?client_id=${appId}&response_type=code&redirect_uri=${window.location.origin}/sconfirm&approval_prompt=force&scope=activity:read&state=`,
      "_self"
    );
  }

  function requestFitbitAuth() {
    const appId = isStaging ? "23R4BS" : "23RC89";
    const redirectUri = isStaging
      ? "https%3A%2F%2Fmcc-staging-4525c.web.app%2FfitbitAuth"
      : "https%3A%2F%2Frace.mychallengecanada.ca%2FfitbitAuth";

    window.open(
      `https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=${appId}&redirect_uri=${redirectUri}&scope=activity`,
      "_self"
    );
  }

  async function requestGarminAuth() {
    // const tempCallbackURL = encodeURIComponent("http://localhost:3000/garminAuth");
    const getOAuthRequestToken = httpsCallable(getFunctions(), "garmin-getOAuthRequestToken");
    try {
      const result = await getOAuthRequestToken();
      const token = result.data.temporaryToken;
      if (!token) {
        throw new Error("No token");
      }
      // Temp testing callback
      const garminConfirmUrl = `https://connect.garmin.com/oauthConfirm?oauth_token=${token}`;
      // const garminConfirmUrl = `https://connect.garmin.com/oauthConfirm?oauth_token=${token}&oauth_callback=${tempCallbackURL}`;
      setGarminConnectEnabled(true);
      window.open(garminConfirmUrl, "_self");
    } catch (err) {
      console.error(err);
      setGarminConnectEnabled(true);
    }
  }
  return (
    <div className="relative mx-auto max-w-3xl w-full mt-6">
      <h2 className="mb-4">Personal Information</h2>
      <div className="grid grid-cols-[42px,1fr] mb-4 items-center">
        <MailIcon />
        <p className="font-semibold mb-0">{user.email}</p>
      </div>
      {linkedDevice && linkedDevice !== "none" && (
        <div className="grid grid-cols-[42px,1fr] items-center mb-3">
          <LocationIcon />
          <p className="font-semibold mb-0">You are connected with {linkedDevice}!</p>
          {linkedDevice === "Garmin" && <GarminToggle />}
        </div>
      )}
      {linkedDevice === "none" && (
        <>
          <div className="grid grid-cols-[42px,1fr] items-center mb-3">
            <LocationIcon color={colors.fitbitBlue} />
            <button
              className="font-semibold mb-0 w-fit underline cursor-pointer"
              style={{ color: colors.fitbitBlue }}
              onClick={(e) => {
                requestFitbitAuth();
              }}
            >
              Connect your Fitbit
            </button>
          </div>
          <div className="grid grid-cols-[42px,1fr] items-center mb-3">
            <LocationIcon />
            <button
              className="font-semibold mb-0 w-fit underline cursor-pointer"
              // style={{ color: colors.fitbitBlue }}
              disabled={!garminConnectEnabled}
              onClick={(e) => {
                setGarminConnectEnabled(false);
                requestGarminAuth();
              }}
            >
              Connect your Garmin
              {!garminConnectEnabled && <Spinner className="ml-2" size="sm" />}
            </button>
          </div>
          {/* <div className="grid grid-cols-[42px,1fr] items-center mb-3">
            <LocationIcon color={colors.stravaOrangeDark} />
            <button
              className="font-semibold mb-0 w-fit underline cursor-pointer"
              style={{ color: colors.stravaOrangeDark }}
              onClick={(e) => {
                e.preventDefault();
                if (!showStravaConnect) {
                  window.scrollTo(0, e.target.offsetTop);
                }
                setShowStravaConnect((prev) => !prev);
              }}
            >
              Connect your Strava
            </button>
          </div> */}
          <div className="mb-4">
            {showStravaConnect && (
              <div className="anim-card w-full p-3 bg-slate-300 mb-3">
                <p>
                  You can automatically upload the following activities to your challenges by connecting your Strava account: Run (indoor or
                  outdoor), Trail Run, Walk, Hike, Virtual Run, Handcycle, Wheelchair.
                </p>
                <p>You can upload any other activities manually.</p>
                <p>
                  You can connect any sportwacth or fitness tracking to Strava. Only activities performed
                  after the synchronization will be uploaded.
                </p>
                <p>
                  Once you have synced your Strava account. You need to go to each of the challenges and toggle the button to post your
                  activities on that specific challenge.
                </p>
                <button className="w-full" onClick={(e) => requestStravaAuth()}>
                  <img src={process.env.PUBLIC_URL + "/img/btn_strava_connectwith_orange.png"} alt="Strava Button" />
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <div className="grid grid-cols-[42px,1fr] items-center mb-3">
        <LocationIcon />
        <h5 className="font-semibold mb-0">Shipping Address</h5>
      </div>
      <div className="ml-10 mb-3">
        <p>Address: {userDetails?.address}</p>
        <p>City: {userDetails?.city}</p>
        <p> Province: {userDetails?.state}</p>
        <p>Zip Code: {userDetails?.postalCode}</p>
        <p>Country: {userDetails?.country}</p>
        <p className="">Phone Number: {userDetails?.phoneNumber}</p>
      </div>
      <div className="grid grid-cols-[42px,1fr] items-center mb-3">
        <SprintIcon />
        <h5 className="font-semibold mb-0">Races Registered</h5>
      </div>
      {racesMap ? racesMap : <Spinner />}
    </div>
  );
};

export default AccountPage;
